<template>
  <div class="dashboard">
<div class="right-col">
  <UsersSummary/>
<MarketInfo :coinsToman="coinsToman" :coinsTether="coinsTether" :tether="state.dashboardType" :key="state.dashboardType+ 'marketInfo'" :loading="loading"/>
 <ActiveOrders/>
</div>
    <div class="left-col">
      <Inventory/>
      <Wallets :coinsToman="coinsToman" :coinsTether="coinsTether" :tether="state.dashboardType" :key="state.dashboardType + 'wallets'" :loading="loading"/>
      <DashboardCoinsSlider :coinsToman="coinsToman" :coinsTether="coinsTether" :tether="state.dashboardType" :key="state.dashboardType + 'dashboard-carousel'" :loading="loading"/>

    </div>
  </div>
</template>

<script>
import UsersSummary from "@/components/Panel/Dashboard/right-column/UsersSummary.vue";
import MarketInfo from "@/components/Panel/Dashboard/right-column/MarketInfo.vue";
// import OpenOrderTable from "@/components/Panel/Dashboard/right-column/OpenOrderTable.vue";
import Inventory from "@/components/Panel/Dashboard/left-column/Inventory.vue";
import Wallets from "@/components/Panel/Dashboard/left-column/Wallets.vue";
import DashboardCoinsSlider from "@/components/Panel/Dashboard/left-column/DashboardCoinsSlider.vue";
import ActiveOrders from "../History/ActiveOrders.vue";


export default{
  name:'Dashboard',
  components: { Wallets, Inventory, MarketInfo, UsersSummary, DashboardCoinsSlider, ActiveOrders },
  data() {
    return {
      coinsToman:[],
      coinsTether:[],
      pricetype:'TOMAN',
      loading:false,
    }
  },
  methods:{
    async getCoins() {
      this.loading = true
      const res = await this.$axios.get('/trades/market-info',{
        params: {
          weeklyDataIncluded: true,
        }
      })
      this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN'))
      this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER'))
   this.loading=false;
    },
  },
  mounted(){
    this.getCoins();
  }
}
</script>

<style lang="scss" scoped>
.loader{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;

  .right-col {
    flex: 2 2 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .left-col {
    flex: 1 1 375px;
    display: flex;
    flex-direction: column;
    max-width: 490px;
    gap: 15px;


  }
}

</style>