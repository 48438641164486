<template>
  <div class="carousel relative">
    <div class="loader"  v-if="loading">
      <spinner-loading />
    </div>
<Hooper :settings="hoopersSetting">

<Slide v-for="(coin,index) in coinsTether" :key="index">
  <div class="card d-flex flex-col">
    <div class="coin-name d-flex justify-space-between">
      <img :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'" alt="" width="28" height="28">

      <span class="d-flex flex-col">
            <span class="small"> {{ $coinLabel[coin.relatedCoin] }} </span>
                <span class="small gray"> {{ coin.relatedCoin }} </span>
</span>

      <TrendChart class="chart"
                  :datasets="[
                                  {
                                  data: coin.lastWeekData,
                                  smooth: true,
                                  fill: false,
                                  className:coin.last24HourChange > 0 ? 'curveBlue':'curveRed',
                                  }
                                  ]"
                  :grid="{
                                  verticalLines: false,
                                  horizontalLines: false,
                                  }"
                  :min="0"
      />


    </div>
    <div class="price">
      <div v-if="coin.relatedCoin !== 'USDT'" :class="coin.last24HourChange > 0 ? 'success':'fail'"
           class="tetherprice"> {{ $toLocal(coin.lastPrice, 2) }} تتر
      </div>

      <span :class="coin.last24HourChange > 0 ? 'success':'fail'" class="tomanprice">

                          <span :class="{'success' : coin.last24HourChange >0 , 'fail' :coin.last24HourChange< 0}">
                                {{ coin.last24HourChange.toPrecision(3) }}%
                                <inline-svg :class="coin.last24HourChange > 0 ? 'green rotate':'red'"
                                            :src="require('@/assets/Icons/arrow.svg')"/>
                            </span>
              {{ $toLocal(coinsToman[index].lastPrice, 0) + `  TMN` }}

            </span>

    </div>

  </div>
</Slide>





</Hooper>
  </div>
</template>

<script>
import {Hooper, Slide} from 'hooper'
import 'hooper/dist/hooper.css';
import SpinnerLoading from "@/components/tools/Spinner";
export default {
  name: "CoinsCarousel",
  props: ['coinsToman', 'coinsTether','loading'],
  components: {
    SpinnerLoading,
    Hooper,
    Slide
  },
  data() {
    return {
      hoopersSetting:{

        rtl:true,
        infiniteScroll:true,
        // autoPlay:true,
        autoplaySpeed: 4000,
        itemsToShow: 1,
        centerMode:false,
        breakpoints : {
          1200:{
            itemsToShow: 2,
            centerMode:true,
          },
          800:{
            itemsToShow: 2,
            // centerMode:true,
          },
          500:{
            itemsToShow:2,

          },
          320:{
            itemsToShow:1,
            // centerMode:true,
          }
        }
      },
      responsive: [
        {
          "breakpoint": 1200,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "infinite": true,
            "dots": false
          }
        },
        {
          "breakpoint": 992,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "infinite": true,
            "dots": false,
            "arrow": false
          }
        },
        {
          "breakpoint": 600,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "initialSlide": 2,
            "arrows": false
          }
        },
        {
          "breakpoint": 480,
          "settings": {
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "initialSlide": 1,
            // "centerMode": true,
            "arrows": false
          }
        }
      ],

      settings: {
        rtl: true,
        arrows: true,
        autoplay: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 600,
        useCSS: true,
        // centerMode:true,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.carousel{
  min-height: 200px;
}
.carousels {
  text-align: center;
}

.card {
  align-items: center;
  justify-content: center;
  box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
  border-radius: 8px;
  max-width: 221px;
  height: 200px;
  padding: 28px;
  background: var(--darker-white);
}

.price {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-self: center;

  .tetherprice {
    font-weight: 700;
    font-size: clamp(16px, 2vw, 20px);
    text-align: center;
    background: none !important;
  }

  .tomanprice {
    font-weight: 400;
    font-size: clamp(10px, 1vw, 12px);
    text-align: center;
    background: none !important;
  }
}

.chart {
  height: 40px;
  width: 50%;
  align-self: center;
}
</style>

<style lang="scss">
//.slick-slider {
//  display: flex !important;
//  justify-content: center !important;
//}
//
//.slick-list {
//  width: 80% !important;
//  padding: 20px 0 !important;
//}

.slick-list {

  height: 160px !important;
}


@media(max-width: 500px){
  .carousel{
    width: unset;
    max-width: 250px;
  }
  .card{
    max-width: unset;
    width: 100%;
    margin: 0 auto;
  }

  .hooper-slide{
width: 100% !important;
  }
}
</style>
<!--<style>-->
<!--.slick-slider {-->
<!--  display: flex !important;-->
<!--  width: 100%;-->
<!--}-->


<!--.slick-prev {-->
<!--  left: 100px !important;-->
<!--  font-size: 14px;-->
<!--  display: inline-block;-->
<!--  cursor: pointer;-->

<!--  background-position: center;-->
<!--  background-repeat: no-repeat;-->
<!--  position: absolute;-->
<!--  outline: none !important;-->
<!--}-->

<!--.slick-next {-->
<!--  right: 100px !important;-->
<!--  font-size: 14px;-->
<!--  display: inline-block;-->
<!--  cursor: pointer;-->

<!--  background-size: inherit;-->
<!--  background-position: center;-->
<!--  background-repeat: no-repeat;-->
<!--  position: absolute;-->
<!--  outline: none !important;-->
<!--}-->

<!--.slick-prev::before {-->
<!--  color: transparent !important;-->
<!--}-->


<!--.slick-next::before {-->
<!--  color: transparent !important;-->
<!--}-->


<!--.curveBlue .fill {-->
<!--  fill: url('#btcFill1');-->
<!--  transition: fill 5s linear;-->
<!--}-->

<!--.curveBlue .stroke {-->
<!--  stroke: #8bd1ff !important;-->
<!--  stroke-width: 2 !important;-->
<!--  transition: fill 5s linear;-->
<!--}-->

<!--.curveRed .fill {-->
<!--  fill: url('#btcFill2');-->
<!--  transition: fill 5s linear;-->
<!--}-->

<!--/*.box:hover .curveRed .fill{*/-->
<!--/*  fill: url('#btcFillHover1');*/-->
<!--/*}*/-->

<!--/*.box:hover .curveBlue .fill{*/-->
<!--/*  fill: url('#btcFillHover2');*/-->
<!--/*}*/-->


<!--.curveRed .stroke {-->
<!--  stroke: rgba(250, 17, 185, 0) !important;-->
<!--  stroke-width: 2 !important;-->
<!--  transition: fill 5s linear;-->
<!--}-->

<!--</style>-->