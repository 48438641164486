<template>
  <div class="summary">
    <div class="box">
      <div class="content">
        <span>دوستان من</span>
        <span>{{ invited }}</span>
      </div>
      <img src="../../../../assets/Icons/dash-invite.svg" alt="">
    </div>
    <div class="box">
      <div class="content">
        <span>دریافتی از دعوت</span>
        <span>{{ profit }}</span>
      </div>
      <img src="../../../../assets/Icons/dash-prize.svg" alt="">
    </div>
    <div class="box">
      <div class="content">
        <span>برداشتی</span>
        <span>{{ withdraw }}</span>
      </div>
      <img src="../../../../assets/Icons/dash-wit.svg" alt="">
    </div>
    <div class="box">
      <div class="content">
        <span>واریزی</span>
        <span>{{ deposit }}</span>
      </div>
      <img src="../../../../assets/Icons/dash-depo.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersSummary",
  data() {
    return {
      alertLink: false,
      invited: 0,
      profit: 0,
      reffreals: "topnance.com/sign-up?ref=",
      refferalsCode: "",
      withdraw: 0,
      deposit: 0
    }
  },
  // methods: {
  //   async getCode() {
  //     const res = await this.$axios('/users/invites')
  //     console.table(res);
  //     this.refferalsCode = res.baseDTO.referralCode
  //     this.reffreals = this.reffreals + this.refferalsCode
  //     this.invited = res.baseDTO.countInvitees
  //     this.profit = res.baseDTO.profit
  //
  //   },
  // },
  // mounted() {
  //   this.getCode()
  // }
}
</script>

<style lang="scss" scoped>
.summary {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  .box {
    background: #F8F8F8;
    /* dashboard cards */
    box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
    border-radius: 10px;
    flex: 1 1 250px;
    padding: 1rem;
    width: 100%;
    max-width: 218px;
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    min-height: 80px;
    align-items: center;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 500px) {
  .box{
    // min-width: 85%;
    width: 100%;
    max-width: unset !important;
  }
}


@media (min-width:1440px){
  .box{
    max-width: unset !important;
    flex:1;
  }
}
</style>