var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carousel relative"},[(_vm.loading)?_c('div',{staticClass:"loader"},[_c('spinner-loading')],1):_vm._e(),_c('Hooper',{attrs:{"settings":_vm.hoopersSetting}},_vm._l((_vm.coinsTether),function(coin,index){return _c('Slide',{key:index},[_c('div',{staticClass:"card d-flex flex-col"},[_c('div',{staticClass:"coin-name d-flex justify-space-between"},[_c('img',{attrs:{"src":'/coins/'+_vm.$coin2Snake[coin.relatedCoin]+'.png',"alt":"","width":"28","height":"28"}}),_c('span',{staticClass:"d-flex flex-col"},[_c('span',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$coinLabel[coin.relatedCoin])+" ")]),_c('span',{staticClass:"small gray"},[_vm._v(" "+_vm._s(coin.relatedCoin)+" ")])]),_c('TrendChart',{staticClass:"chart",attrs:{"datasets":[
                                  {
                                  data: coin.lastWeekData,
                                  smooth: true,
                                  fill: false,
                                  className:coin.last24HourChange > 0 ? 'curveBlue':'curveRed',
                                  }
                                  ],"grid":{
                                  verticalLines: false,
                                  horizontalLines: false,
                                  },"min":0}})],1),_c('div',{staticClass:"price"},[(coin.relatedCoin !== 'USDT')?_c('div',{staticClass:"tetherprice",class:coin.last24HourChange > 0 ? 'success':'fail'},[_vm._v(" "+_vm._s(_vm.$toLocal(coin.lastPrice, 2))+" تتر ")]):_vm._e(),_c('span',{staticClass:"tomanprice",class:coin.last24HourChange > 0 ? 'success':'fail'},[_c('span',{class:{'success' : coin.last24HourChange >0 , 'fail' :coin.last24HourChange< 0}},[_vm._v(" "+_vm._s(coin.last24HourChange.toPrecision(3))+"% "),_c('inline-svg',{class:coin.last24HourChange > 0 ? 'green rotate':'red',attrs:{"src":require('@/assets/Icons/arrow.svg')}})],1),_vm._v(" "+_vm._s(_vm.$toLocal(_vm.coinsToman[index].lastPrice, 0) + " TMN")+" ")])])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }