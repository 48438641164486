<template>
<div class="inventory">
 <div class="loader" v-if="loading">
   <spinner-loading/>
 </div>
  <div class="right-col">
    <span>
<img src="../../../../assets/Icons/dash-wit.svg" alt="" width="50" height="50">
       <span class="red regular">برداشت   تومانی</span>
    </span>
    <span>
<img src="../../../../assets/Icons/dash-depo.svg" alt="" width="50" height="50">
      <span class="green regular">واریز   تومانی</span>
    </span>
  </div>
<div class="left-col">
  <span class="gray regular align-self-end">ارزش کل دارایی</span>
  <span class="price"><h1 class="secondary">{{$toLocal(wholePrice,'TOMAN')}}</h1>
  <span class="gray small">
    تومان
  </span>
  </span>
  <div class="success align-self-end">
    <span class="green">
      <span class="percentage ">0.05%</span>
      </span>
  </div>

</div>

</div>
</template>

<script>
import SpinnerLoading from "@/components/tools/Spinner";
export default {
  name: "Inventory",
  components: {SpinnerLoading},
  data(){
    return{
      loading:false,
      wholePrice:'',

    }
  },
  methods:{
    async getWallets(){
      this.loading= true;
const res= await this.$axios('/wallets/customer-addresses',{
params:{
  walletType:'P2P'
}
});
// console.log(res);
this.wholePrice = Object.entries(res).map(item=>item[1]).map(item=>item.wholePriceOfCredit).reduce((a,b)=>a+b);
this.loading=false;
    }
  },
  mounted() {
    this.getWallets();
  }
}
</script>

<style lang="scss" scoped>
.loader{
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.inventory{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
  background-image: url("../../../../assets/panel/dash-balance.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 231px;
  .right-col{
    display: flex;
align-items: flex-end ;
    gap: 20px;
    height: 100%;
    span{
      display: flex;
      flex-direction: column;
    }
  }
  .left-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.price{
  display: flex;
  gap: 4px;
  align-items: center;
  direction: ltr;
}

@media(max-width: 500px){
  .inventory{
    padding: 10px;
     max-width: 100%;
  }
  h1{
    font-size: 18px;
  }
}
</style>