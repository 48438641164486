<template>
<div class="">
  <div class="market-table relative">
    <div class="loader" v-if="loading">
      <spinner-loading />
    </div>
    <template v-if="tether =='TOMAN'">
      <div class="table-container hover" v-for="(item,index) in coinsToman" :key="index" @click="$router.push('/Dashboard/Wallet')">
  <span class="info">

    <img :src="`/coins/${$coin2Snake[item.relatedCoin]}.png`" alt="" width="28" height="28">

  <span class="name small">
    <span>{{ $coinLabel[item.relatedCoin] }}</span>
    <small class="gray">{{ item.relatedCoin }}</small>
  </span>

  </span>
        <span class="price small">
    <span >
    {{ tether  ==='TETHER'?$toLocal(randomNumber,$decimal['TETHER']) : $toLocal(randomNumber,$decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
    </span>

<span class="last">مقدار موجودی</span>

  </span>
        <span class="price small">
  <span >
    {{ tether  ==='TETHER'?$toLocal(item.maxPrice,$decimal['TETHER']) : $toLocal(item.maxPrice,$decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
  </span>
<!--<span class="last">بیشترین قیمت خرید</span>-->

  </span>
      </div>
    </template>
    <template v-if="tether==='TETHER'">
      <div class="table-container hover" v-for="(item,index) in coinsTether" :key="index" @click="$router.push('/Dashboard/Wallet')">
  <span class="info">

    <img :src="`/coins/${$coin2Snake[item.relatedCoin]}.png`" alt="" width="28" height="28">

  <span class="name small">
    <span>{{ $coinLabel[item.relatedCoin] }}</span>
    <small class="gray">{{ item.relatedCoin }}</small>
  </span>

  </span>
        <span class="price small">
    <span >
    {{ tether  ==='TETHER'?$toLocal(randomNumber,$decimal['TETHER']) : $toLocal(randomNumber,$decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
    </span>

<span class="last">مقدار موجودی</span>

  </span>
        <span class="price small">
  <span >
    {{ tether  ==='TETHER'?$toLocal(item.maxPrice,$decimal['TETHER']) : $toLocal(item.maxPrice,$decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
  </span>
          <!--<span class="last">بیشترین قیمت خرید</span>-->

  </span>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import SpinnerLoading from "@/components/tools/Spinner";
export default {
  name: "Wallets",
  components: {SpinnerLoading},
  props: ['coinsToman', 'coinsTether', 'tether','loading'],
  computed:{
    randomNumber(){
      return Math.random()*100;
    }
  }
}
</script>

<style lang="scss" scoped>
.market-table {
  text-align: center;
  background: #F8F8F8;
  /* dashboard cards */
  width: 100%;

  box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
  border-radius: 12px;
  max-height: 321px !important;
  min-height: 321px !important;
  overflow-y: auto;
  padding: 12px 20px;
  flex: 2 2 350px;
  display: grid;
  grid-template-columns: (3, 1fr);

  .table-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid var(--gray-lighter);

    .info {
      display: flex;
         min-width: 30%;
         text-align: right !important;
      gap: 4px;

      .name {
        display: flex;
        flex-direction: column;
      }

      .gray {
        font-size: clamp(8px, 1vw, 10px);
        color: #8C8585;
      }
    }
    .price{
      min-width: 30%;
      text-align: center !important;
      display:flex;
      flex-direction: column;
      text-align: right;
      span{
        small{
          font-size: clamp(8px, 1vw, 10px);
          color: #8C8585;
        }
      }
      .last{
        font-size: clamp(6px, 1vw, 8px);
        color: #8C8585;
      }
    }
    &:hover{
      transition: 700ms;
      background: var(--gray-lighter) !important;
      border-radius: 4px;
    }

  }

}

@media (max-width: 500px) {
.name{
  display: none !important;
}

  .market-table{
    // max-width: 90%;
  }
}

@media (min-width:1440px){
  .market-table{
    max-width: unset !important;
    flex:1;
  }
}
</style>