<template>
  <div class="market-info">
    <div class="market-table">
      <!--      <template v-if="tether =='TOMAN'">-->
      <!--        <div class="table-container hover pointer" v-for="(item,index) in coinsToman" :key="index" @click.prevent="$router.push(`/Dashboard/trade/TOMAN/${$coin2Snake[item.relatedCoin]}`)">-->
      <!--  <span class="info">-->

      <!--    <img :src="`/coins/${$coin2Snake[item.relatedCoin]}.png`" alt="" width="28" height="28">-->

      <!--  <span class="name">-->
      <!--    <span>{{ $coinLabel[item.relatedCoin] }}</span>-->
      <!--    <small class="gray">{{ item.relatedCoin }}</small>-->
      <!--  </span>-->

      <!--  </span>-->
      <!--          <span class="price">-->
      <!--    <span class="red">-->
      <!--    {{ tether === 'TETHER' ? $toLocal(item.minPrice, $decimal['TETHER']) : $toLocal(item.minPrice, $decimal['TOMAN']) }}-->
      <!--            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>-->
      <!--    </span>-->

      <!--<span class="last">کمترین قیمت فروش</span>-->

      <!--  </span>-->
      <!--          <span class="price">-->
      <!--  <span class="green">-->
      <!--    {{ tether === 'TETHER' ? $toLocal(item.maxPrice, $decimal['TETHER']) : $toLocal(item.maxPrice, $decimal['TOMAN']) }}-->
      <!--            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>-->
      <!--  </span>-->
      <!--<span class="last">بیشترین قیمت خرید</span>-->

      <!--  </span>-->
      <!--        </div>-->
      <!--      </template>-->
<template>

<div class="tables relative">
  <div class="loader" v-if="loading">
    <spinner-loading/>
  </div>
  <table v-if="tether==='TOMAN'" class="w100 table" :key="tether">
    <tr class="hover" v-for="(item,index) in coinsToman" :key="index"
        @click.prevent="$router.push(`/Dashboard/trade/TOMAN/${$coin2Snake[item.relatedCoin]}`)">
      <td class="d-flex align-items-center gap-xss">
        <img :src="`/coins/${$coin2Snake[item.relatedCoin]}.png`" alt="" width="28" height="28">

        <span class="name d-flex flex-col">
    <span>{{ $coinLabel[item.relatedCoin] }}</span>
    <small class="gray">{{ item.relatedCoin }}</small>
  </span>


      </td>
      <td>          <span class="price">
    <span class="red">
    {{ tether === 'TETHER' ? $toLocal(item.minPrice, $decimal['TETHER']) : $toLocal(item.minPrice, $decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
    </span>

<span class="last  gray">کمترین قیمت فروش</span>

  </span></td>
      <td>
           <span class="price last-td">
  <span class="green">
    {{ tether === 'TETHER' ? $toLocal(item.maxPrice, $decimal['TETHER']) : $toLocal(item.maxPrice, $decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
  </span>
<span class="last">بیشترین قیمت خرید</span>

  </span>

      </td>
    </tr>
  </table>
</div>
  <table v-if="tether==='TETHER'" class="w100 table" :key="tether">
    <tr class="hover" v-for="(item,index) in coinsTether" :key="index"
        @click.prevent="$router.push(`/Dashboard/trade/TOMAN/${$coin2Snake[item.relatedCoin]}`)">
      <td class="d-flex align-items-center gap-xss">
        <img :src="`/coins/${$coin2Snake[item.relatedCoin]}.png`" alt="" width="28" height="28">

        <span class="name d-flex flex-col">
    <span>{{ $coinLabel[item.relatedCoin] }}</span>
    <small class="gray">{{ item.relatedCoin }}</small>
  </span>


      </td>
      <td>          <span class="price">
    <span class="red">
    {{ tether === 'TETHER' ? $toLocal(item.minPrice, $decimal['TETHER']) : $toLocal(item.minPrice, $decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
    </span>

<span class="last  gray">کمترین قیمت فروش</span>

  </span></td>
      <td>
           <span class="price last-td">
  <span class="green">
    {{ tether === 'TETHER' ? $toLocal(item.maxPrice, $decimal['TETHER']) : $toLocal(item.maxPrice, $decimal['TOMAN']) }}
            <small>{{ tether == 'TETHER' ? 'USDT' : 'TMN' }}</small>
  </span>
<span class="last">بیشترین قیمت خرید</span>

  </span>

      </td>
    </tr>
  </table>
</template>
    </div>
    <div class="fees">
      <div class="fee-box">
        <div class="col-right">
          <h2> کارمزد
            <span class="green">{{ state.userInfo.fee }} %</span>
          </h2>
          <span class="gray">حجم معاملات تا سطح بعد</span>
          <span class="secondary"><span class="gray">TMN</span>{{ state.userInfo.remain }} </span>
        </div>
        <div class="col-left d-flex flex-col">
          <apexchart :responsive="responsiveCircle" class="chart" ref="realtimeChart" :options="circularOption"
                     :series='CircleSeries'
                     :labels="label"/>
        </div>
      </div>
      <div class="static-box">
        <div class="title">
          <span>حجم معاملات شما در <span class="secondary">ماه</span></span>
        </div>
        <div class="bar">
          <apexchart :options='barOptions' :series='barSeries' ref='bartimeChart'/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SpinnerLoading from "@/components/tools/Spinner";
export default {
  name: "DashboardMarketInfo",
  components: {SpinnerLoading},
  props: ['coinsToman', 'coinsTether', 'tether','loading'],
  data() {
    return {
      CircleSeries: [this.state.userInfo.fee],
      responsiveCircle: [
        {
          breakpoint: 500,
          options: {
            chart: {
              height: "100px",
              width: '100px'
            }
          }
        }
      ],
      barOptions: {
        chart: {
          id: "basic-bar",
          type: "bar",

          toolbar: {
            show: true,
            legend: false,
            tools: {
              download: false,
            },
          },

        },
        colors: ["#94AAE7"],

        plotOptions: {
          bar: {
            columnWidth: "50%",
            height: '100%',
            width: '100%',
            barHeight: '70%',
            hideOverflowingLabels: false,
            rangeBarOverlap: false,
            rangeBarGroupRows: false,
            borderRadius: 10,
            distributed: false,
            dataLabels: {
              enabled: false,
              position: 'top',
              colors: ["#94AAE7"],
            }
          },
        },
        xaxis: {
          categories: ["JAN", "FEB", "MAR", "APR", "JUN", "JUL", "AUG", "SEP"],
          position: "bottom",
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,

          },
        },

        yaxis: {
          show: false,
          min: 0,
          max: 150,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },

      },

      barSeries: [
        {

          data: [30, 40, 45, 50, 49, 100, 45, 30],
        },
      ],
      //circular chart
      level: 2,
      label: 'سطح',
      circularOption: {
        labels: [],
        chart: {
          height: '100%',
          width: '80px',
          fontFamily: 'IRANSans',

          type: "radialBar",
        },
        colors: ["#20E647"],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            position: 'left',
            track: {
              background: '#333',
              startAngle: -135,
              endAngle: 135,
            },
            dataLabels: {
              name: {
                show: true,
              },
              value: {
                fontSize: "20px",
                show: true
              }
            }
          }
        },


        fill: {
          type: "gradient",

          gradient: {
            shade: "dark",
            type: "horizontal",
            gradientToColors: ["#87D4F9"],
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "butt"
        },


      }
    }
  },
  mounted() {
    this.$refs.realtimeChart.updateOptions({labels: [`سطح ${this.level}`],});
  }
}
</script>

<style lang="scss" scoped>
table {
  td {
    height: 62px;

    padding: 10px;
    border-bottom: 1px solid var(--gray-lighter);
  }

}

.price {
  display: flex;
  flex-direction: column;
  text-align: right;

  span {
    small {
      font-size: clamp(8px, 1vw, 10px);
      color: #8C8585;
    }
  }

  .last {
    font-size: clamp(6px, 1vw, 8px);
    color: #8C8585;
  }
}


.bar {
  align-self: center;
  //width: 280px;
  height: 100%;
}

span {
  font-size: clamp(10px, 1.2vw, 14px);
}

.market-info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .market-table {
    background: #F8F8F8;
    /* dashboard cards */
    width: 100%;
    max-width: 850px !important;
    box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
    border-radius: 12px;
    max-height: 416px;
    min-height: 416px;
    overflow-y: auto;
    padding: 12px 20px;
    flex: 2 2 350px;
    display: grid;
    grid-template-columns: (3, 1fr);

    .table-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 20px;
      border-bottom: 1px solid var(--gray-lighter);

      .info {
        display: flex;
        gap: 4px;

        .name {
          display: flex;
          flex-direction: column;
        }

        .gray {
          font-size: clamp(8px, 1vw, 10px);
          color: #8C8585;
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        text-align: center;

        span {
          small {
            font-size: clamp(8px, 1vw, 10px);
            color: #8C8585;
          }
        }

        .last {
          font-size: clamp(6px, 1vw, 8px);
          color: #8C8585;
        }
      }


    }
  }

  .fees {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    //max-width: 328px;

    .fee-box {
      // flex-wrap: wrap;
      display: flex;
      // padding: 23px;
      padding: 24px;
      //flex-direction: column;
      background: #F8F8F8;
      // max-height: 141px;
      /* dashboard cards */

      box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
      border-radius: 12px;

      .col-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        //align-items: center;
        h2, .green {
          font-size: clamp(16px, 1.5vw, 20px);
          font-weight: bold;

        }

        .gray {
          color: var(--gray-light);
          font-size: clamp(10px, 1vw, 12px);
        }
      }
    }

    .static-box {
      max-height: 224px;
      display: flex;
      padding: 23px;
      flex-direction: column;
      background: #F8F8F8;
      box-shadow: 0px 0px 3px rgba(192, 222, 234, 0.48);
      border-radius: 12px;
      //gap: 10px;

      .title {
        font-size: clamp(10px, 1vw, 12px);
      }

      img {
        width: 100%;
        height: 100%;

      }

    }
  }
}

.price {

  align-items: center;
  text-align: center;
}

.last-td {
  align-items: flex-end !important;
}

.chart {
  // max-width: 150px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 500px) {
  //.bar{
  //  width: 180px;
  //}
  .fees {
    max-width: unset !important;
    width: 100% !important;
  }
  .fee-box {
    flex-direction: column;

  }
  // img{
  //   display: none;
  // }
  .market-info, .market-table {
    // max-width: 250px;

  }
  .market-table {
    padding: 5px !important;
  }

}

@media (min-width: 1440px) {
  .market-table {
    max-width: unset !important;
    flex: 1;
  }
}

</style>